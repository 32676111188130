<template>
  <div class="boards-button">
    <b-button
      id="boards-popover"
      variant="flat-secondary"
      title="Доски"
      class="btn-icon"
      @click="showBoardsPopover = !showBoardsPopover"
    >
      <feather-icon
        size="20"
        icon="FolderIcon"
      />
      <span class="d-inline-block d-md-none">Доски</span>
    </b-button>

    <b-popover
      ref="boards-popover"
      target="boards-popover"
      :show.sync="showBoardsPopover"
      triggers="click"
      :placement="isMobile ? 'bottom' : 'bottom'"
    >
      <div class="d-flex align-items-center justify-content-between mb-50">
        <span class="font-medium-3 font-weight-bolder">Интерактивные доски</span>
        <b-button
          class="btn-icon"
          variant="flat-secondary"
          size="sm"
        >
          <feather-icon
            icon="XIcon"
            size="20"
            @click="showBoardsPopover = false"
          />
        </b-button>
      </div>
      <div class="boards-popover">
        <div>
          <board-button
            v-for="(board, index) in boards"
            :key="`board-tab-${index+1}`"
            :board="board"
            :index="index"
            :is-selected="selectedBoard === board.id"
            :select-board="selectBoard"
            :remove-board="removeBoard"
            :can-delete="boards.length > 1"
            @saved="() => $refs.savedBoards.getBoards()"
          />

          <b-button
            v-if="isTeacher && boards.length < 10"
            variant="flat-secondary"
            title="Добавить доску"
            class="mr-50"
            size="sm"
            @click.prevent="addBoard"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            Добавить доску
          </b-button>
          <div
            v-if="isTeacher"
            class="flex-fill"
          />

          <saved-boards
            v-if="isTeacher"
            ref="savedBoards"
          />
        </div>
      </div>
    </b-popover>
  </div>
</template>

<script>
import {
  BButton,
  BPopover,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { teacherRole, userRoles } from '@/store/user'
import BoardButton from '@/components/video-stream/board/controls/BoardButton.vue'
import SavedBoards from '@/components/video-stream/board/controls/SavedBoards.vue'

export default {
  name: 'StreamBoardControl',
  components: {
    SavedBoards,
    BoardButton,
    BButton,
    BPopover,
  },
  props: {
    boards: {
      type: Array,
      required: true,
    },
    selectBoard: {
      type: Function,
      required: true,
    },
    addBoard: {
      type: Function,
      required: true,
    },
    removeBoard: {
      type: Function,
      required: true,
    },
    selectedBoard: {
      type: Number,
      required: true,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    max: 10,
    showBoardsPopover: false,
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
    }),
    userRole() {
      return this.user ? userRoles[this.user.role] : null
    },
    isTeacher() {
      return this.userRole === teacherRole
    },
  },
}
</script>

<style scoped lang="scss">
  .controlCard {
    border-radius: 0;
  }

  .popover {
    max-width: unset;
  }

  .boards-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    width: 64px;
    height: 64px;
    border-bottom: 1px solid #edeef1;
    position: fixed;
  }

  .boards-popover {
    width: 500px;
    max-height: calc(80vh);
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-track {
      -webkit-border-radius: 10px;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #999999;

      -webkit-border-radius: 10px;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:window-inactive {

    }
  }
</style>
