<template>
  <div
    :id="containerId"
    class="video-container"
    :class="containerClass"
  >
    <video
      v-if="stream"
      :id="streamId"
      type="video"
      controls
      autoplay
      playsinline
      :src-object.prop="streamObject"
      class="main-cam"
      :muted="ownCamera"
      @click="clickAction"
    />
    <div
      v-if="!stream || !videoTrack || !videoTrack.enabled"
      class="cam-placeholder"
    />
    <div
      v-if="stream && !ownCamera && !floating"
      class="cam-info"
      :class="{minimized: minimized}"
    >
      <span
        v-if="userId !== user.id && !minimized"
        class="cam-name"
      >
        {{ username }}
      </span>
      <div
        :key="updateButtons"
        class="cam-actions"
      >
        <b-button
          v-if="audioTrack"
          variant="flat-secondary"
          size="sm"
          class="btn-icon mr-50"
          :title="$t('page.video-stream.grid.toggle_mic')"
          @click="toggleMic"
        >
          <feather-icon
            :icon="ownCamera ? audioTrack.enabled ? 'MicIcon' : 'MicOffIcon' : audioTrack.enabled ? 'Volume2Icon' : 'VolumeXIcon'"
            size="16"
            :class="{'text-danger': !audioTrack.enabled}"
          />
        </b-button>
        <b-button
          v-if="videoTrack"
          variant="flat-secondary"
          size="sm"
          class="btn-icon"
          :title="$t('page.video-stream.grid.toggle_camera')"
          @click="toggleVideo"
        >
          <feather-icon
            :icon="videoTrack && videoTrack.enabled ? 'VideoIcon' : 'VideoOffIcon'"
            :class="{'text-danger': !videoTrack || !videoTrack.enabled}"
            size="16"
          />
        </b-button>
        <b-button
          v-if="isTeacher && !ownCamera"
          v-b-modal.screenshot-modal
          variant="flat-secondary"
          size="sm"
          class="btn-icon ml-50"
          :title="$t('page.video-stream.grid.screenshot.take_screenshot')"
        >
          <feather-icon
            icon="CameraIcon"
            size="16"
          />
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { teacherRole, userRoles } from '@/store/user'

export default {
  name: 'StreamCamera',
  components: {
    BButton,
  },
  props: {
    stream: {
      type: Object,
      default: null,
    },
    containerClass: {
      type: String,
      default: null,
    },
    containerId: {
      type: String,
      default: null,
    },
    clickAction: {
      type: Function,
      default: () => {},
    },
    minimized: {
      type: Boolean,
      default: false,
    },
    floating: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    updateButtons: 0,
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
    }),
    userRole() {
      return this.user ? userRoles[this.user.role] : null
    },
    isTeacher() {
      return this.userRole === teacherRole
    },
    streamId() {
      return this.stream.id
    },
    streamObject() {
      return this.stream.srcObject
    },
    videoTrack() {
      const tracks = this.streamObject.getVideoTracks()
      if (!tracks.length) return null
      return tracks[0]
    },
    audioTrack() {
      const tracks = this.streamObject.getAudioTracks()
      if (!tracks.length) return null
      return tracks[0]
    },
    userId() {
      return this.stream.user.user_id
    },
    username() {
      return this.stream.user.user_name
    },
    ownCamera() {
      return this.userId === this.user.id
    },
    isSharedScreen() {
      return this.stream.user.screenSharing
    },
  },
  methods: {
    toggleVideo() {
      if (this.videoTrack) {
        this.videoTrack.enabled = !this.videoTrack.enabled
        this.updateButtons += 1
      }
    },
    toggleMic() {
      if (this.audioTrack) {
        this.audioTrack.enabled = !this.audioTrack.enabled
        this.updateButtons += 1
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import "~@core/scss/base/core/colors/palette-variables.scss";
.cam-info {
  position: absolute;
  top: 5px;
  left: 5px;
  max-width: 95%;
  z-index: 2;
  background: rgba(255, 255, 255, 0.6);
  padding: 3px 15px;
  border-radius: 10px 5px 5px 5px;
  display: flex;
  align-items: center;

  .cam-name {
    margin-right: 10px;
    color: black;
    font-weight: 500;
  }
  .cam-actions {
    display: flex;
    align-items: center;
    svg {
      color: rgba(0, 0, 0, 0.7);
    }
  }

  &.minimized {
    padding: 0;
    top: 0px;
    left: 0px;
    border-radius: 0 0 5px 0;

    .cam-actions {
      button {
        margin: 0!important;
      }
    }
  }
}

.floating-stream {
  .cam-info {
    top: auto;
    bottom: 5px;
    padding: 2px 7px;
    font-size: 12px;
  }
  .cam-actions {
    svg {
      width: 12px;
      height: 12px;
    }
  }
}
.cam-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-image: url("~@/assets/images/no_camera.png");
  background-size: cover;
  background-position: center;
}
</style>
