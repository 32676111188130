<template>
  <div class="w-100 h-100 d-flex flex-column justify-content-between">
    <div
      :key="stepContainerKey"
      class="step-container"
    >
      <div
        v-if="loadingStep"
        class="p-1 d-flex align-items-center justify-content-center flex-fill"
      >
        <b-spinner
          variant="primary"
          label="Spinning"
          style="width: 2rem; height: 2rem;"
        />
      </div>
      <div v-else-if="step && displayableStepElements.length && selectedLessonStep !== null">
        <div class="p-1">
          <div
            v-for="(element, index) in displayableStepElements"
            :key="element.id"
          >
            <step-elements
              image-as-board
              :element="element"
              :index="index"
            />
          </div>
        </div>
      </div>
      <div v-else-if="exercise && exerciseQuestions.length">
        <div class="p-1">
          <stream-exercise-questions
            in-step
            :exercise-questions="exerciseQuestions"
          />
        </div>
      </div>
    </div>
    <div>
      <div class="thin-divider" />
      <div
        class="d-flex align-items-center"
        style="min-height: 50px"
      >
        <div
          v-if="lessonHasExercises && isTeacher"
          class="p-50 d-flex align-items-center step-modes-container"
        >
          <b-button
            variant="flat-secondary"
            class="btn-icon"
            title="Запустить упражнение"
            @click="$videoStreamEventBus.$emit('set-exercise', { exerciseId: null, exerciseIndex: 0 })"
          >
            <feather-icon
              size="18"
              icon="CheckSquareIcon"
            />
          </b-button>
        </div>
        <div class="p-50 flex-fill d-flex align-items-center">
          <b-button
            v-if="isTeacher"
            variant="flat-secondary"
            class="btn-icon"
            :disabled="stepIndex === 0"
            @click="changeStep(stepIndex - 1)"
          >
            <feather-icon
              class="mr-50"
              icon="ChevronLeftIcon"
            />
            <span>предыдущий</span>
          </b-button>
          <div class="flex-fill" />
          <div>
            <span>{{ currentStep }}</span>
            <span class="ml-50 mr-50">/</span>
            <span>{{ meta.totalSteps }}</span>
          </div>
          <div class="flex-fill" />
          <b-button
            v-if="isTeacher"
            variant="flat-secondary"
            class="btn-icon"
            :disabled="stepIndex === meta.totalSteps - 1"
            @click="changeStep(stepIndex + 1)"
          >
            <span>следующий</span>
            <feather-icon
              class="ml-50"
              icon="ChevronRightIcon"
            />
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton, BSpinner } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { studentRole, teacherRole, userRoles } from '@/store/user'
import StepElements from '@/components/new_courses/course/theme/lesson/step/components/StepElements.vue'
import StreamExerciseQuestions from './StreamExerciseQuestions.vue'

export default {
  name: 'StreamSteps',
  components: {
    StreamExerciseQuestions,
    StepElements,
    BButton,
    BSpinner,
  },
  props: {
    stepIndex: {
      type: Number,
      default: 0,
    },
    lessonHasExercises: {
      type: Boolean,
      default: false,
    },
    updateIndex: {
      type: Function,
      required: true,
    },
    steps: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    meta: {
      totalSteps: 0,
      perPage: 1,
    },
    loadingStep: false,
    stepContainerKey: 0,
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
    }),
    selectedLessonStep() {
      return this.steps[this.stepIndex] || {}
    },
    step() {
      const { step } = this.selectedLessonStep
      if (!step || !Object.keys(step).length) return { ...this.selectedLessonStep }
      return { ...step }
    },
    exercise() {
      return { ...this.selectedLessonStep.exercise }
    },
    exerciseQuestions() {
      return this.exercise?.exercisesContents || []
    },
    displayableStepElements() {
      return (this.step?.stepElements || []).filter(el => el.disabled === Number(false))
    },
    name() {
      return this.elementByType('name')
    },
    image() {
      return this.elementByType('image')
    },
    text() {
      return this.elementByType('text')
    },
    video() {
      return this.elementByType('video')
    },
    test() {
      return this.elementByType('test')
    },
    currentStep() {
      return this.stepIndex + 1
    },
    userRole() {
      return this.user ? userRoles[this.user.role] : ''
    },
    isTeacher() {
      return this.userRole === teacherRole
    },
    isStudent() {
      return this.userRole === studentRole
    },
  },
  watch: {
    stepIndex() {
      this.stepContainerKey += 1
    },
  },
  mounted() {
    this.meta.totalSteps = this.steps.length
  },
  methods: {
    elementByType(type) {
      let el = null
      this.displayableStepElements.forEach(element => {
        if (element.type === type && !element.disabled) {
          el = { ...element }
          try {
            el.data = JSON.parse(el.data)
            // eslint-disable-next-line no-empty
          } catch (e) { }
        }
      })
      return el
    },
    changeStep(index) {
      this.updateIndex(index)
      this.$videoStreamEventBus.$emit('set-steps-index', index)
    },
  },
}
</script>

<style scoped lang="scss">
.step-container {
  max-height: calc(100vh - 104px);
  overflow: auto;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {}

  &::-webkit-scrollbar-thumb {
    background-color: #999999;
  }
  &::-webkit-scrollbar-thumb:window-inactive {}
}
</style>
