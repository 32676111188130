<template>
  <div>
    <!--    <b-button-->
    <!--      id="saved-boards-popover"-->
    <!--      :title="`Сохраненные доски`"-->
    <!--      variant="outline-primary"-->
    <!--    >-->
    <!--      <feather-icon-->
    <!--        icon="SaveIcon"-->
    <!--        class="mr-50"-->
    <!--      />-->
    <!--      Сохраненные-->
    <!--    </b-button>-->

    <!--    <b-popover-->
    <!--      ref="saved-boards-popover"-->
    <!--      target="saved-boards-popover"-->
    <!--      :show.sync="showSavedBoards"-->
    <!--      triggers="click"-->
    <!--      placement="left"-->
    <!--      container="board-tabs-container"-->
    <!--    >-->
    <div class="d-flex align-items-center justify-content-between">
      <!--      <span class="font-medium-3 font-weight-bolder">Сохраненные доски</span>-->
      <!--      <b-button-->
      <!--        class="btn-icon"-->
      <!--        variant="flat-secondary"-->
      <!--        size="sm"-->
      <!--      >-->
      <!--        <feather-icon-->
      <!--          icon="XIcon"-->
      <!--          size="20"-->
      <!--          @click="showSavedBoards = false"-->
      <!--        />-->
      <!--      </b-button>-->
    </div>
    <div class="saved-boards-popover">
      <div class="thin-divider mt-50 mb-1" />
      <div :key="updateContainer">
        <div
          v-if="boards.length"
          class="d-flex flex-wrap "
          style="margin-left: -0.5rem; margin-right: -0.5rem"
        >
          <saved-board-card
            v-for="board in boards"
            :key="board.id"
            :board="board"
            :board-restored="() => showSavedBoards = false"
            :board-deleted="() => getBoards()"
          />
        </div>
        <div
          v-else
          class="d-flex justify-content-center"
        >
          <p>Нет сохраненных досок</p>
        </div>
      </div>
    </div>
    <!--    </b-popover>-->
  </div>
</template>

<script>
import {
// BButton,
// BPopover
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { teacherRole, userRoles } from '@/store/user'
import SavedBoardCard from '@/components/video-stream/board/controls/SavedBoardCard.vue'

export default {
  name: 'BoardButton',
  components: {
    SavedBoardCard,
    // BButton,
    // BPopover,
  },
  data: () => ({
    showSavedBoards: false,
    updateContainer: 0,
    boards: [],
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
    }),
    userRole() {
      return this.user ? userRoles[this.user.role] : ''
    },
    isTeacher() {
      return this.userRole === teacherRole
    },
  },
  watch: {
    showSavedBoards(newValue) {
      if (newValue) {
        this.getBoards()
      }
    },
  },
  mounted() {
    this.getBoards()
  },
  methods: {
    saveBoard(boardId) {
      this.$videoStreamEventBus.$emit('save-board-state', boardId)
    },
    getBoards() {
      let boards = localStorage.getItem('saved_boards')
      if (boards) boards = JSON.parse(boards)
      else boards = []
      this.boards = boards
    },
  },
}
</script>

<style scoped lang="scss">
.popover {
  max-width: unset;
}
.saved-boards-popover {
  width: 500px;
  max-height: calc(100vh - 320px);
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #999999;

    -webkit-border-radius: 10px;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:window-inactive {

  }
}
</style>
