<template>
  <div
    :id="boardContainerId"
    class="d-flex h-100"
    style="z-index: 95"
  />
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import { format, getUnixTime } from 'date-fns'
import { dataURLtoFile } from '@core/utils/utils'
import { teacherRole, userRoles } from '@/store/user'

export default {
  name: 'StreamBoard',
  props: {
    backgroundColor: {
      type: String,
      default: '#e7e8ec',
    },
    boardId: {
      type: Number,
      default: 0,
    },
    isActive: {
      type: Boolean,
      default: true,
    },
    toolsPosition: {
      type: String,
      default: 'left',
    },
  },
  data: () => ({
    designer: null,
    canvasBackground: null,
    canvasData: {},
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
      session: 'videoStream/get_session',
    }),
    userRole() {
      return this.user ? userRoles[this.user.role] : null
    },
    isTeacher() {
      return this.userRole === teacherRole
    },
    boardContainerId() {
      return `board-${this.boardId}`
    },
  },
  destroyed() {
    this.$videoStreamEventBus.$off('sync-own-big-board')
    this.$videoStreamEventBus.$off('set-big-board-background-image')
    this.$videoStreamEventBus.$off('save-board-state')
  },
  mounted() {
    this.initDesigner()
    this.$videoStreamEventBus.$on('sync-own-big-board', ({ data, id }) => {
      if (this.boardId === id) {
        this.designer.syncData(data)
      }
    })
    this.$videoStreamEventBus.$on('set-big-board-background-image', ({ image, data }) => {
      if (this.isActive) {
        this.designer.setBackgroundImage(image)
        this.canvasBackground = image
        this.designer.syncData(data)
        this.saveCanvasData(data)
        this.$videoStreamEventBus.$emit('sync-foreign-big-board', { data, id: this.boardId })
      }
    })
    this.$videoStreamEventBus.$on('save-board-state', boardId => {
      if (boardId === this.boardId) {
        this.saveBoard()
      }
    })
  },
  methods: {
    ...mapActions({
      saveBoardToServer: 'videoStream/saveBoard',
      addFileToSessionStore: 'videoStream/addFileToSessionStore',
    }),
    initDesigner() {
      // eslint-disable-next-line no-undef
      this.designer = new CanvasDesigner()

      this.designer.widgetHtmlURL = '/canvas_designer/widget.html'// you can place this file anywhere
      this.designer.widgetJsURL = '/canvas_designer/widget.js' // you can place this file anywhere

      this.designer.addSyncListener(data => {
        this.saveCanvasData(data)
        this.$videoStreamEventBus.$emit('sync-foreign-big-board', { data, id: this.boardId })
      })

      this.designer.setToolsPosition(this.toolsPosition)
      this.designer.setSelected('pencil')
      this.designer.setTools({
        pencil: true,
        lineWidth: true,
        colorsPicker: true,
        pointer: true,
        background: this.isTeacher,
        marker: true,
        text: true,
        eraser: true,
        dragSingle: true,
        image: true,
        undo: this.isTeacher,
        rectangle: true,
        line: true,
        clear: this.isTeacher,
        clearBackground: this.isTeacher,
        arc: true,
        // dragMultiple: true,
        // quadratic: false,
        // pdf: false,
        // arrow: false,
        // bezier: false,
        // zoom: false,
        // extraOptions: false,
        // code: false,
      })

      this.$nextTick(() => {
        this.designer.appendTo(document.getElementById(this.boardContainerId))
      })
    },
    saveCanvasData(data) {
      const { points, startIndex } = data
      if (points) {
        if (!this.canvasData.points) this.canvasData.points = []
        if (startIndex === 0) {
          this.canvasData.points = points
        } else {
          this.canvasData.points = [...this.canvasData.points, ...points]
        }
        if (this.canvasData.startIndex === undefined) this.canvasData.startIndex = 0
      }
    },
    async saveBoard() {
      if (!this.session.id) return
      const iframe = document.getElementById(this.boardContainerId).children[0]
      const canvas = iframe.contentWindow.document.getElementById('main-canvas')
      const image = canvas.toDataURL()
      const file = dataURLtoFile(image, `session-${this.session.id}-board-${Date.now()}`)
      await this.addFileToSessionStore({ sessionId: this.session.id, type: 'board', file })

      const name = format(Date.now(), 'dd.MM.yyyy HH:mm')
      const id = getUnixTime(Date.now())
      await this.saveBoardToServer({
        id, name, data: this.canvasData, background: this.canvasBackground, preview: image,
      })
      this.$videoStreamEventBus.$emit('board-saved')
    },
  },
}
</script>
