<template>
  <b-modal
    id="screenshot-modal"
    v-model="screenshotDialog"
    size="lg"
    centered
    :title="$t('page.video-stream.grid.screenshot.take_screenshot')"
    body-class="p-0"
    no-close-on-backdrop
  >
    <img
      v-if="screenshotUrl"
      id="screenshot-photo"
      style="width: 100%"
      :src="screenshotUrl"
      alt
    >
    <div
      v-else
      class="d-flex align-items-center justify-content-center w-100"
      style="height: 300px"
    >
      <span>{{ $t('page.video-stream.grid.screenshot.loading_image') }}</span>
    </div>
    <template #modal-footer>
      <div class="d-flex align-items-center justify-content-end">
        <b-button
          class="mr-1"
          variant="outline-secondary"
          size="sm"
          @click="closeScreenshotDialog"
        >
          {{ $t('components.button.labels.cancel') }}
        </b-button>
        <b-button
          v-if="screenshotUrl"
          variant="success"
          size="sm"
          @click="saveScreenshot"
        >
          {{ $t('components.button.labels.save') }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { BModal, BButton } from 'bootstrap-vue'
import { dataURLtoFile } from '@core/utils/utils'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ScreenshotModal',
  components: {
    BModal,
    BButton,
  },
  props: {
    screenshot: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    screenshotDialog: false,
    screenshotUrl: null,
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
      session: 'videoStream/get_session',
    }),
  },
  watch: {
    screenshotDialog() {
      if (this.screenshotDialog) this.takeStudentScreenshot()
    },
    screenshot() {
      this.screenshotUrl = this.screenshot
    },
  },
  methods: {
    ...mapActions({
      addFileToSessionStore: 'videoStream/addFileToSessionStore',
    }),
    closeScreenshotDialog() {
      this.screenshotDialog = false
      setTimeout(() => {
        this.screenshotUrl = null
      }, 1000)
    },
    async saveScreenshot() {
      const file = dataURLtoFile(this.screenshotUrl, `session-${this.session.id}-screenshot-${Date.now()}`)
      await this.addFileToSessionStore({ sessionId: this.session.id, type: 'image', file })
      this.closeScreenshotDialog()
    },
    takeStudentScreenshot() {
      this.$videoStreamEventBus.$emit('stream-take-screenshot')
    },
  },
}
</script>

<style scoped>

</style>
