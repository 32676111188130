<template>
  <div
    id="stream-wrapper"
    ref="wrapper"
    class="video-wrapper max-content-height"
    :class="{streamsNumberClass, 'board-enabled': boardEnabled}"
  >
    <div
      v-if="boardEnabled"
      style="position: absolute; right: 1rem; top: 1rem; z-index: 9999999;"
      @click="$emit('onFullscreenToggle')"
    >
      <b-button
        class="btn-icon"
        variant="flat-secondary"
      >
        <feather-icon :icon="isFullscreen ? 'Minimize2Icon' : 'Maximize2Icon'" />
      </b-button>
    </div>

    <stream-boards
      class="stream-board"
      :enabled="boardEnabled"
      :class="{enabled: boardEnabled}"
      :background-color="backgroundColor"
    />

    <!--   MAIN CAM    -->
    <stream-camera
      container-class="main-cam-container"
      container-id="main-cam-container"
      :stream="mainStream"
      :minimized="boardEnabled"
    />

    <!--   ATTACHED CAMS    -->
    <div
      v-if="attachedStreams.length > 1"
      id="attached-streams"
      class="attached-streams"
      :class="streamsNumberClass"
    >
      <stream-camera
        v-for="stream in attachedStreams"
        :key="stream.id"
        :stream="stream"
        :click-action="() => swapCams(stream)"
        :minimized="boardEnabled"
      />
    </div>
    <div
      v-else-if="attachedStreams.length === 1"
      class="floating-stream"
      :class="{'board-enabled': boardEnabled}"
    >
      <stream-camera
        :stream="attachedStreams[0]"
        :click-action="() => swapCams(attachedStreams[0])"
        :minimized="boardEnabled"
        floating
      />
    </div>

    <screenshot-modal :screenshot="screenshot" />
    <canvas
      id="screenshot-canvas"
      style="display: none"
    />
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { teacherRole, userRoles } from '@/store/user'
import { setUserSelectedSpeakers } from '@/libs/rtc'
import ScreenshotModal from '@/components/video-stream/actions/ScreenshotModal.vue'
import StreamCamera from '@/components/video-stream/StreamCamera.vue'
import StreamBoards from '@/components/video-stream/StreamBoards.vue'

export default {
  name: 'StreamGrid',
  components: {
    StreamBoards,
    StreamCamera,
    ScreenshotModal,
    BButton,
  },
  props: {
    streams: {
      type: Array,
      default: () => [],
    },
    boardEnabled: {
      type: Boolean,
      default: false,
    },
    swapCams: {
      type: Function,
      required: true,
    },
    screenshot: {
      type: String,
      default: '',
    },
    isFullscreen: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    mainStreamStyle: {
      width: '100%',
      height: '100%',
    },
    attachedStreamsStyle: {
      width: '100%',
      height: '100%',
    },
    wrapperStyle: {
      width: 0,
      height: 0,
    },
    dish: null,
  }),
  destroy() {
    window.removeEventListener('resize', () => {})
  },
  computed: {
    ...mapGetters({
      user: 'user/get_user',
    }),
    userRole() {
      return this.user ? userRoles[this.user.role] : null
    },
    isTeacher() {
      return this.userRole === teacherRole
    },
    minimizedSizes() {
      return {
        width: this.wrapperStyle.width / 2,
        height: this.wrapperStyle.height / 3,
      }
    },
    minimizedStyles() {
      return `width: ${this.minimizedSizes.width}px; height: ${this.minimizedSizes.height}px; left: 0;`
    },
    streamsNumberClass() {
      return `streams-${this.attachedStreams.length}`
    },
    backgroundColor() {
      return '#e7e8ec'
    },
    mainStream() {
      return this.streams.find(stream => stream.main)
    },
    attachedStreams() {
      return this.streams.filter(stream => !stream.main)
    },
  },
  watch: {
    mainStream() {
      if (this.mainStream) {
        this.$nextTick(() => {
          setUserSelectedSpeakers(document.getElementById(this.mainStream.id))
          this.resizeMainStream()
        })
      }
    },
    attachedStreams() {
      this.$nextTick(() => {
        this.resizeMainStream()
      })
    },
  },
  mounted() {
    this.wrapperStyle.width = this.$refs.wrapper.clientWidth
    this.wrapperStyle.height = this.$refs.wrapper.clientHeight
    window.addEventListener('resize', () => {
      this.resizeMainStream()
    })

    this.$nextTick(() => {
      this.resizeMainStream()
    })
  },
  methods: {
    resizeMainStream() {
      const wrapper = document.getElementById('stream-wrapper')
      const maxWidth = wrapper.parentElement.scrollWidth
      const wrapperHeight = wrapper.parentElement.scrollHeight
      const attachedStreams = document.getElementById('attached-streams')
      let attachedStreamsHeight = 0
      if (attachedStreams) {
        attachedStreamsHeight = attachedStreams.scrollHeight
      }
      let mainCamHeight = wrapperHeight - attachedStreamsHeight
      let ratioX = 4
      let ratioY = 3
      if (this.mainStream.user.screenSharing || this.attachedStreams.length > 1) {
        ratioX = 16
        ratioY = 9
      }
      let mainCamWidth = mainCamHeight * (ratioX / ratioY)
      if (mainCamWidth > maxWidth) {
        mainCamWidth = maxWidth - 20
        mainCamHeight = mainCamWidth * (ratioY / ratioX)
      }
      const mainCam = document.getElementById('main-cam-container')
      if (mainCam) {
        mainCam.style.width = `${mainCamWidth}px`
        mainCam.style.height = `${mainCamHeight}px`
      }
    },
  },
}
</script>

<style lang="scss">

@keyframes show {
  0% {
    opacity: 0;
    transform: scale(0.4) translateY(20px);
  }

  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

.video-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: relative;
  z-index: 93;
  background: black;
  transition: background 0.3s ease-in-out;

  .main-cam-container {
    transition: all 0.5s ease-in-out;
    z-index: 96;
    .main-cam {
    }
  }

  .attached-streams {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    z-index: 888;
    grid-gap: 0.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;

    .video-container {
      padding-top: 56%;
      cursor: pointer;
    }
  }

  .video-container {
    //margin: 10px;
    position: relative;
    vertical-align: middle;
    align-self: center;
    //border-radius: 10px;
    overflow: hidden;
    display: inline-block;
    background: black;
    animation: show 0.4s ease;

    &::before {
      content: "Загрузка...";
      color: white;
      position: absolute;
      z-index: 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    video {
      position: absolute;
      right: 0;
      object-fit: cover;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: #000;
      border-radius: 10px;
      overflow: hidden;
      left: 0;
      top: 0;
      background-size: cover;
      overflow: hidden;
      -webkit-transition: margin-top 1s ease-in-out;
      -moz-transition: margin-top 1s ease-in-out;
      -o-transition: margin-top 1s ease-in-out;
      transition: margin-top 1s ease-in-out;
    }
  }

  .floating-stream {
    position: absolute;
    z-index: 97;
    width: 250px;
    bottom: 30px;
    left: 30px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 12px 20px 3px #00000082;
    transition: 0.5s ease-in-out;

    .video-container {
      margin: 0;
      width: 100%;
      padding-top: 56.25%;
      cursor: pointer;
    }
  }

  &.board-enabled {
    background: unset;

    .main-cam-container {
      position: absolute;
      bottom: 0px;
      left: 64px;
      width: 167px!important;
      height: 124px!important;
      box-shadow: 0px 12px 20px 3px #00000082;
      border-radius: 0px;

      video {
        border-radius: 0px;
      }
    }

    .floating-stream {
      left: 64px;
      bottom: 0px;
      width: 67px;
      box-shadow: 0px 4px 7px 0px #00000082;
      border-radius: 0px;

      video {
        border-radius: 0px;
      }
    }
  }

  .stream-board {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh!important;
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s ease;
    transform: scale(1.5);

    &.enabled {
      visibility: visible;
      transition: all 0.5s ease;
      opacity: 1;
      position: unset;
      transform: scale(1);
    }
  }
}

@media (max-width: 1200px) {
  .video-wrapper {
    grid-template-rows: 1fr 1fr;

    .attached-streams {
      display: grid;
      grid-template-rows: 1fr 1fr;
      grid-template-columns: repeat(2, 1fr);

      &.streams-1 {
        grid-template-rows: 1fr;
        grid-template-columns: repeat(1, 1fr);
      }

      &.streams-2 {
        grid-template-columns: repeat(2, 1fr);
      }

      &.streams-3 {
        grid-template-columns: repeat(3, 1fr);
      }

    }
  }
}

video::-webkit-media-controls {
  display: none !important;
}
</style>
