<template>
  <div>

    <!--    <b-popover-->
    <!--      :ref="`popover-${board.id}`"-->
    <!--      :target="`popover-reactive-${board.id}`"-->
    <!--      :show.sync="board.showMenu"-->
    <!--      triggers="hover"-->
    <!--      placement="auto"-->
    <!--      :delay="500"-->
    <!--      container="board-tabs-container"-->
    <!--    >-->
    <!--      <div-->
    <!--        style="min-width: 100px"-->
    <!--        @mouseleave="board.showMenu = false;"-->
    <!--      >-->
    <!--    <div class="font-medium-3 font-weight-bolder">-->
    <!--      <span>Доска {{ index+1 }}</span>-->
    <!--    </div>-->
    <div v-if="isTeacher">
      <b-card
        no-body
        border-variant="secondary"
        class="mb-50 shadow-none hoverable cursor-pointer"
        @click="isTeacher ? selectBoard(board.id) : ''"
      >
        <div class="d-flex align-items-center">
          <b-button
            :id="`popover-reactive-${board.id}`"
            :title="`Доска ${index+1}`"
            :variant="isSelected ? 'primary' : 'flat-secondary'"
            class="mr-50 position-relative"
          >
            <span :class="{'text-white': isSelected}">
              Доска {{ index+1 }}
            </span>
          </b-button>

          <div class="flex-fill" />

          <b-button
            variant="flat-primary"
            class="mr-50"
            size="sm"
            :disabled="saving"
            @click.stop="saveBoard(board.id)"
          >
            <feather-icon
              class="mr-50"
              :icon="saving ? 'LoaderIcon' : 'SaveIcon'"
            />
            <div class="d-none d-sm-inline-block">
              <span v-if="saving">Сохранение</span>
              <span v-else>Сохранить</span>
            </div>
          </b-button>
          <b-button
            v-if="canDelete"
            size="sm"
            variant="flat-danger"
            class="mr-50"
            @click.stop="board.showMenu = false; removeBoard(board.id)"
          >
            <feather-icon
              class="mr-50"
              icon="TrashIcon"
            />
            <span class="d-none d-sm-inline-block">Удалить</span>
          </b-button>
        </div>
      </b-card>
    </div>
    <!--      </div>-->
    <!--    </b-popover>-->
  </div>
</template>

<script>
import {
  BCard,
  BButton,
  // BPopover
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { teacherRole, userRoles } from '@/store/user'

export default {
  name: 'BoardButton',
  components: {
    BCard,
    BButton,
    // BPopover,
  },
  props: {
    isSelected: {
      type: Boolean,
      default: false,
    },
    canDelete: {
      type: Boolean,
      default: true,
    },
    board: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    selectBoard: {
      type: Function,
      required: true,
    },
    removeBoard: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    saving: false,
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
    }),
    userRole() {
      return this.user ? userRoles[this.user.role] : null
    },
    isTeacher() {
      return this.userRole === teacherRole
    },
  },
  destroyed() {
    this.$videoStreamEventBus.$off('board-saved')
  },
  mounted() {
    this.$videoStreamEventBus.$on('board-saved', () => {
      this.saving = false
      this.board.showMenu = false
      this.$emit('saved')
    })
  },
  methods: {
    saveBoard(boardId) {
      this.saving = true
      this.$videoStreamEventBus.$emit('save-board-state', boardId)
    },
  },
}
</script>

<style scoped lang="scss">
.controlCard-number {
  right: 12px;
  color: #82868b;
  font-weight: bold;
  position: absolute;
  bottom: 14px;
  font-size: 12px;
  text-align: center;
}

.popover-body {
  padding: 0!important;
}
</style>
