<template>
  <div class="d-flex flex-column">
    <stream-board-control
      :boards="boards"
      :add-board="addBoard"
      :select-board="selectBoard"
      :remove-board="removeBoard"
      :selected-board="activeBoard"
      :is-mobile="toolsPosition === 'top'"
    />
    <div class="flex-fill full-height">
      <stream-board
        v-for="board in boards"
        :key="board.id"
        :tools-position="toolsPosition"
        :board-id="board.id"
        :is-active="boardIsActive(board)"
        :style="{display: boardIsActive(board) ? 'flex!important' : 'none!important'}"
      />
    </div>
  </div>
</template>

<script>
import StreamBoard from '@/components/video-stream/board/StreamBoard.vue'
import StreamBoardControl from '@/components/video-stream/board/StreamBoardControl.vue'

export default {
  name: 'StreamBoards',
  components: { StreamBoardControl, StreamBoard },
  props: {
    backgroundColor: {
      type: String,
      default: '#e7e8ec',
    },
    enabled: {
      type: Boolean,
      default: false,
    },
    toolsPosition: {
      type: String,
      default: 'left',
    },
  },
  data: () => ({
    data: {
      boards: [],
      activeBoard: 0,
      lastId: 0,
    },
    fullscreen: false,
  }),
  computed: {
    boards() {
      return this.data.boards
    },
    activeBoard() {
      return this.data.activeBoard
    },
    lastId() {
      return this.data.lastId
    },
  },
  watch: {
    boards() {
      this.syncBoardsState()
    },
    activeBoard() {
      this.syncBoardsState()
    },
  },
  destroyed() {
    this.$videoStreamEventBus.$off('set-big-board-background-image')
    this.$videoStreamEventBus.$off('sync-boards-state-remote')
  },
  mounted() {
    this.$videoStreamEventBus.$on('set-big-board-background-image', () => {
      if (!this.enabled) this.$videoStreamEventBus.$emit('stream-toggle-board')
    })
    this.$videoStreamEventBus.$on('sync-boards-state-remote', data => {
      const dt = { ...data }
      const boards = []
      dt.boards.forEach(board => {
        boards.push({ ...board, showMenu: false })
      })
      dt.boards = boards
      this.data = { ...dt }
    })
    this.data.boards.push({
      id: 0,
      showMenu: false,
    })
    this.$videoStreamEventBus.$emit('sync-boards-state', this.data)
  },
  methods: {
    addBoard() {
      this.data.lastId += 1
      this.boards.push({
        id: this.lastId,
        showMenu: false,
      })
      this.selectBoard(this.lastId)
    },
    selectBoard(id) {
      this.data.activeBoard = id
    },
    removeBoard(id) {
      let index = 0
      this.data.boards.forEach((board, i) => {
        if (board.id === id) index = i
      })
      this.data.boards = this.data.boards.filter(board => board.id !== id)
      if (this.activeBoard === id) {
        if (this.data.boards[index - 1]) this.selectBoard(this.data.boards[index - 1].id)
        else this.selectBoard(this.data.boards[index].id)
      }
    },
    boardIsActive(board) {
      return this.data.activeBoard === board.id
    },
    syncBoardsState() {
      this.$videoStreamEventBus.$emit('sync-boards-state', this.data)
    },
  },
}
</script>

<style scoped>

</style>
