<template>
  <b-card
    no-body
    class="stream-sidepanel d-flex flex-column justify-content-start h-100 mb-0 rounded-0"
  >
    <video-stream-controls
      :show-course-select="showCourseSelect"
      :is-board="isBoard"
      :stream="stream"
      :is-shared-screen="isSharedScreen"
      @onScreenShare="$emit('onScreenShare')"
    />

    <StreamStepsComponent />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import VideoStreamControls from '@/components/video-stream/actions/VideoStreamControls.vue'
import StreamStepsComponent from './StreamStepsComponent.vue'

export default {
  name: 'VideoStreamActions',
  components: {
    StreamStepsComponent,
    VideoStreamControls,
    BCard,
  },
  props: {
    isBoard: {
      type: Boolean,
      required: true,
    },
    isSharedScreen: {
      type: Boolean,
      default: false,
    },
    showCourseSelect: {
      type: Boolean,
      required: true,
    },
    stream: {
      type: Object,
      required: true,
    },
  },

}
</script>

<style scoped lang="scss">
.stream-sidepanel {
  position: relative;
  z-index: 101;
  box-shadow: 0px 2px 2px 0px #0000001c;
}
</style>
