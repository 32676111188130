<template>
  <div
    style="flex-basis: 50%"
    class="mb-1"
  >
    <b-card
      no-body
      border-variant="secondary"
      class="course-card full-height mb-0 cursor-pointer"
      @click="restoreBoard"
    >
      <div
        class="board-preview"
        :style="{backgroundImage: `url(${board.background})`}"
      >
        <div
          :style="{backgroundImage: `url(${board.preview})`}"
          class="board-preview-curves"
        />
      </div>
      <div class="pl-50 d-flex align-items-center justify-content-between">
        <p class="font-small-3 mb-0 font-weight-bolder">
          {{ board.name }}
        </p>
        <b-button
          class="btn-icon"
          variant="flat-danger"
          size="sm"
          title="Удалить"
          @click.stop="deleteSavedBoard()"
        >
          <feather-icon
            icon="XIcon"
            size="17"
          />
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BButton } from 'bootstrap-vue'
import { mapActions } from 'vuex'

export default {
  name: 'SavedBoardCard',
  components: {
    BCard, BButton,
  },
  props: {
    board: {
      type: Object,
      required: true,
    },
    boardRestored: {
      type: Function,
      required: true,
    },
    boardDeleted: {
      type: Function,
      required: true,
    },
  },
  methods: {
    ...mapActions({
      deleteBoard: 'videoStream/removeSavedBoard',
    }),
    restoreBoard() {
      this.$videoStreamEventBus.$emit('set-big-board-background-image', { data: this.board.data, image: this.board.background })
      this.boardRestored()
    },
    async deleteSavedBoard() {
      await this.deleteBoard(this.board.id)
      this.boardDeleted()
    },
  },
}
</script>

<style scoped lang="scss">
.board-preview {
  width: 100%;
  height: 120px;
  background-size: cover;
  position: relative;
  background-position: center;

  .board-preview-curves {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 120px;
  }

  p {
    position: absolute;
    z-index: 2;
    opacity: 0.6;
    left: 10px;
    top: 5px;
    color: black;
  }

  .btn {
    position: absolute;
    z-index: 2;
    opacity: 0.6;
    right: 10px;
    top: 5px;
    color: black;
  }

  //&::after {
  //  content: '';
  //  position: absolute;
  //  top: 0;
  //  right: 0;
  //  bottom: 0;
  //  left: 0;
  //  background: linear-gradient(0deg, rgb(255 251 251 / 0%) 18%, rgb(255 255 255 / 88%) 100%);
  //}
}
</style>
