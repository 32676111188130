<template>
  <div
    :key="updateSteps"
    :class="{'h-100': !isMobile}"
  >
    <stream-steps
      v-if="mode === 'steps'"
      :steps="selectedLessonSteps"
      :step-index="stepIndex"
      :update-index="(index) => stepIndex = index"
      :lesson-has-exercises="selectedLessonHasExercises"
    />
    <stream-exercises
      v-else-if="mode === 'exercises'"
      :exercises="selectedLessonExercises"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import StreamExercises from './actions/StreamExercises.vue'
import StreamSteps from './actions/StreamSteps.vue'
import { studentRole, teacherRole, userRoles } from '../../store/user'

export default {
  name: 'StreamStepsComponent',
  components: { StreamSteps, StreamExercises },
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    mode: 'select',
    stepIndex: 0,
    exerciseIndex: 0,
    selectedLessonId: null,
    updateSteps: 0,
    selectedLessonExercises: [],
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
      currentLessonTab: 'videoStream/get_current_lesson_tab',
      selectedLessonSteps: 'videoStream/get_steps',
      session: 'videoStream/get_session',
    }),
    userRole() {
      return this.user ? userRoles[this.user.role] : ''
    },
    isTeacher() {
      return this.userRole === teacherRole
    },
    isStudent() {
      return this.userRole === studentRole
    },
    selectedLessonHasExercises() {
      return this.selectedLessonExercises.length > 1
    },
  },
  mounted() {
    this.$videoStreamEventBus.$on('set-steps', ({ steps, stepIndex }) => {
      this.setStepsMode(steps, stepIndex)
    })
    this.$videoStreamEventBus.$on('set-steps-index', stepIndex => {
      this.stepIndex = stepIndex
      this.$videoStreamEventBus.$emit('stream-set-step-index', this.stepIndex)
    })

    this.$videoStreamEventBus.$on('set-exercise', ({ exerciseId, exerciseIndex }) => {
      this.setExercisesMode(exerciseId, exerciseIndex)
    })
    this.$videoStreamEventBus.$on('close-exercise', () => {
      this.mode = 'steps'
      this.setExercises([])
      this.$videoStreamEventBus.$emit('stream-set-mode', this.mode)
    })

    this.$videoStreamEventBus.$on('socket-set-mode', mode => {
      this.mode = mode
    })
    this.$videoStreamEventBus.$on('socket-set-step-index', stepIndex => {
      this.stepIndex = stepIndex
    })
  },
  methods: {
    ...mapActions({
      setSteps: 'videoStream/setLessonSteps',
      setExercises: 'videoStream/setLessonExercises',
      setCurrentLessonTab: 'videoStream/setCurrentLessonTab',
      saveLessonHistory: 'videoStream/saveLessonToHistory',
    }),
    setStepsMode(steps, stepIndex) {
      this.stepIndex = stepIndex
      this.setSteps(steps)
      const selectedStep = steps[stepIndex]
      const lessonId = selectedStep?.lesson_id
      this.mode = 'steps'
      if (this.isTeacher) {
        this.$videoStreamEventBus.$emit('stream-set-step', {
          id: lessonId,
          index: stepIndex,
          mode: this.mode,
          exercisesMode: 'training',
          currentExerciseSelectedAnswers: [],
          currentExerciseAnswered: false,
          totalCorrect: 0,
        })
      }
      this.updateSteps += 1
      this.getLessonExercises(lessonId)
      this.saveLessonHistory(lessonId)
    },
    async setExercisesMode(exerciseId, exerciseIndex) {
      this.exerciseIndex = exerciseIndex
      this.mode = 'exercises'
      if (this.isTeacher && exerciseId) {
        this.$videoStreamEventBus.$emit('stream-set-exercise', {
          exerciseId,
          index: exerciseIndex,
          mode: this.mode,
          exercisesMode: 'training',
          currentExerciseSelectedAnswers: [],
          currentExerciseAnswered: false,
          totalCorrect: 0,
        })
      }
      this.updateSteps += 1
    },
    async getLessonExercises(lessonId) {
      const { items } = await this.$http.get(`/exercises/index?lessons_id=${lessonId}&expand=exercisesContents`)
      this.selectedLessonExercises = items
    },
  },
}
</script>

<style scoped>

</style>
