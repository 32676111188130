<template>
  <b-modal
    id="end-session-modal"
    ref="modal"
    v-model="dialog"
    size="sm"
    centered
    hide-footer
    body-class="p-0"
    :title="$t(`page.video-stream.modals.end_session.title`)"
  >
    <form @submit.prevent="confirm()">
      <div class="p-1">
        <p>{{ $t(`page.video-stream.modals.end_session.subtitle`) }}</p>
        <b-form-group>
          <b-form-input
            v-model="name"
            required
            :placeholder="`${$t(`page.video-stream.modals.end_session.name`)}*`"
          />
          <div
            v-if="lessonsHistory.length > 1"
            class="d-flex align-items-center flex-wrap mt-50"
          >
            <b-button
              v-for="lesson in lessonsHistory"
              :key="lesson.id"
              variant="flat-info"
              size="sm"
              @click="name = lesson.name"
            >
              {{ lesson.name }}
            </b-button>
          </div>
        </b-form-group>
        <b-form-textarea
          v-model="comment"
          :placeholder="$t(`page.video-stream.modals.end_session.comment`)"
          rows="3"
        />
      </div>
      <div class="thin-divider" />
      <div class="p-1 w-100 d-flex align-items-center justify-content-between modal-custom-footer">
        <div class="flex-fill" />
        <b-button
          variant="primary"
          size="sm"
          type="submit"
        >
          Завершить
        </b-button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import {
  BModal, BButton, BFormGroup,
  BFormTextarea, BFormInput,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  name: 'EndSessionConfirm',
  components: {
    BModal,
    BButton,
    BFormTextarea,
    BFormInput,
    BFormGroup,
  },
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    name: null,
    comment: null,
  }),
  computed: {
    ...mapGetters({
      session: 'videoStream/get_session',
      lessonsHistory: 'videoStream/get_current_session_lessons',
    }),
  },
  methods: {
    open() {
      this.dialog = true
      this.name = this.session.name
      if (this.lessonsHistory.length === 1) {
        this.name = this.lessonsHistory[0].name
      }
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    confirm() {
      this.resolve({
        result: true,
        name: this.name,
        comment: this.comment,
      })
      this.name = null
      this.comment = null
      this.dialog = false
    },
    cancel() {
      this.name = null
      this.comment = null
      this.resolve({ result: false })
      this.dialog = false
    },
  },
}
</script>

<style scoped>

</style>
