<template>
  <div>
    <div class="d-flex flex-row justify-content-end align-items-center w-100 p-50">

      <b-button
        id="settings-popover"
        title="Настройки микрофона"
        variant="flat-primary"
        class="btn-icon mr-50"
        @click="toggleMic"
      >
        <feather-icon
          size="16"
          :icon="audioTrack.enabled ? 'MicIcon' : 'MicOffIcon'"
          :class="{'text-danger': !audioTrack.enabled}"
        />
      </b-button>

      <b-button
        class="btn-icon mr-50"
        variant="flat-primary"
        :title="$t('page.video-stream.grid.toggle_camera')"
        @click="toggleVideo"
      >
        <feather-icon
          size="18"
          :icon="videoTrack && videoTrack.enabled ? 'VideoIcon' : 'VideoOffIcon'"
          :class="{'text-danger': !videoTrack || !videoTrack.enabled}"
        />
      </b-button>

      <b-button
        class="d-none d-md-block btn-icon mr-50"
        :variant="isSharedScreen ? 'primary' : 'flat-primary'"
        title="Демонстрация экрана"
        @click="$emit('onScreenShare')"
      >
        <feather-icon
          size="18"
          icon="AirplayIcon"
        />
      </b-button>

      <b-popover
        ref="settings-popover"
        target="settings-popover"
        triggers="hover"
        :delay="{ show: 300, hide: 200 }"
        placement="bottom"
        custom-class="p-0"
        :show.sync="showSettingsPopover"
      >
        <div
          :key="updateButtons"
          class="settings-popover p-50 d-flex align-items-center"
        >
          <vue-slider
            v-model="micGain"
            :min="0"
            :max="2"
            :interval="0.01"
            contained
            class="flex-fill ml-50 mr-50"
            drag-on-click
            :marks="micGainMarks"
            title="Громкость микрофона"
            :class="{'vue-slider-danger': micGain > 1.25}"
            :tooltip-formatter="value => (value * 100).toFixed(0)"
            hide-label
          />
        </div>
      </b-popover>

      <b-button
        class="mr-50"
        :variant="isBoard ? 'flat-primary' : 'flat-secondary'"
        @click="$videoStreamEventBus.$emit('stream-toggle-board')"
      >
        <feather-icon
          class="mr-1"
          icon="Edit3Icon"
        />
        <span>{{ $t('page.video-stream.stream-actions.buttons.board') }}</span>
      </b-button>

      <b-button
        v-if="isTeacher"
        :variant="showCourseSelect ? 'flat-primary' : 'flat-secondary'"
        @click="$videoStreamEventBus.$emit('select-course')"
      >
        <feather-icon
          class="mr-1"
          icon="BookOpenIcon"
        />
        <span>{{ $t('page.video-stream.stream-actions.buttons.select-lesson') }}</span>
      </b-button>

      <div class="flex-fill" />

      <b-button
        variant="flat-danger"
        class="btn-icon"
        :title="$t('page.video-stream.stream-actions.buttons.end-stream')"
        @click="$videoStreamEventBus.$emit('stream-end')"
      >
        <feather-icon icon="LogOutIcon" />
      </b-button>
    </div>
    <div class="thin-divider" />
  </div>
</template>

<script>
import {
  BButton, BPopover,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import VueSlider from 'vue-slider-component'
import { studentRole, teacherRole, userRoles } from '@/store/user'

export default {
  name: 'VideoStreamControls',
  components: {
    BButton,
    BPopover,
    VueSlider,
  },
  props: {
    isBoard: {
      type: Boolean,
      required: true,
    },
    isSharedScreen: {
      type: Boolean,
      default: false,
    },
    showCourseSelect: {
      type: Boolean,
      required: true,
    },
    stream: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    showSettingsPopover: false,
    micGain: 0.5,
    updateButtons: 0,
    micGainMarks: [0, 1],
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
    }),
    userRole() {
      return this.user ? userRoles[this.user.role] : null
    },
    isTeacher() {
      return this.userRole === teacherRole
    },
    isStudent() {
      return this.userRole === studentRole
    },
    streamSrc() {
      return this.stream.srcObject
    },
    videoTrack() {
      const tracks = this.streamSrc.getVideoTracks()
      if (!tracks.length) return null
      return tracks[0]
    },
    audioTrack() {
      const tracks = this.streamSrc.getAudioTracks()
      if (!tracks.length) return null
      return tracks[0]
    },
  },
  watch: {
    micGain(newValue) {
      this.modifyGain(newValue)
    },
  },
  mounted() {
    this.micGain = localStorage.getItem('gain') || 1
  },
  methods: {
    toggleVideo() {
      if (this.videoTrack) {
        this.videoTrack.enabled = !this.videoTrack.enabled
        this.updateButtons += 1
      }
    },
    toggleMic() {
      if (this.audioTrack) {
        this.audioTrack.enabled = !this.audioTrack.enabled
        this.updateButtons += 1
      }
    },
    modifyGain(gain) {
      if (gain === 0) {
        this.audioTrack.enabled = false
      } else {
        this.audioTrack.enabled = true
      }
      localStorage.setItem('gain', gain)
      this.stream.gainNode.gain.value = gain
    },
  },
}
</script>

<style scoped lang="scss">
.popover {
  max-width: unset;
}
.settings-popover {
  width: 200px;
}
</style>
