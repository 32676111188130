<template>
  <div class="full-height">
    <stream-exercise-questions v-if="selectedExerciseQuestions.length" />
    <div
      v-else
      class="nice-scrollbar exercises-list"
    >
      <div class="p-1">
        <div
          class="d-flex flex-wrap"
          style="margin-left: -0.5rem; margin-right: -0.5rem"
        >
          <stream-exercise-card
            v-for="(exercise) in exercises"
            :key="exercise.id"
            :exercise="exercise"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import StreamExerciseQuestions from '@/components/video-stream/actions/StreamExerciseQuestions.vue'
import StreamExerciseCard from './StreamExerciseCard.vue'

export default {
  name: 'StreamExercises',
  components: {
    StreamExerciseCard,
    StreamExerciseQuestions,
  },
  props: {
    exercises: {
      type: Array,
      required: true,
    },
  },
  data: () => ({

  }),
  computed: {
    ...mapGetters({
      selectedExerciseQuestions: 'videoStream/get_exercises',
    }),
  },
  mounted() {

  },
}
</script>

<style scoped lang="scss">
.exercises-list {
  height: calc(100vh - 55px);
  overflow: auto;
}
</style>
