<template>
  <div>
    <div class="mb-1">
      <b-input-group>
        <b-input-group-prepend is-text>
          <feather-icon icon="SearchIcon" />
        </b-input-group-prepend>
        <b-form-input
          v-model="search"
          placeholder="поиск"
          @keyup.enter="searchUpdated()"
          @input="!search ? searchUpdated() : ''"
        />
        <b-input-group-append is-text>
          <feather-icon icon="XIcon" />
        </b-input-group-append>
      </b-input-group>
    </div>
    <div
      v-if="loading"
      class="d-flex align-items-center justify-content-center flex-fill"
    >
      <b-spinner
        variant="primary"
        label="Spinning"
        style="width: 2rem; height: 2rem;"
      />
    </div>
    <div
      v-else-if="!courses.length"
      class="d-flex flex-column align-items-center justify-content-center mt-2"
    >
      <p class="font-medium-3">
        Нет доступных курсов
      </p>
    </div>
    <div v-else>
      <div
        class="d-flex flex-wrap"
        style="margin-left: -0.5rem; margin-right: -0.5rem"
      >
        <div
          v-for="course in courses"
          :key="course.id"
          style="flex-basis: 50%"
          class="mb-1"
        >
          <b-card
            no-body
            border-variant="secondary"
            class="course-card full-height mb-0 cursor-pointer"
            @click="courseSelected(course)"
          >
            <b-card-body class="p-1">
              <p class="font-medium-1 font-weight-bold">
                {{ course.name }}
              </p>
              <div class="font-small-4 text-secondary">
                {{ course.description }}
              </div>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BInputGroup, BInputGroupAppend, BInputGroupPrepend, BFormInput,
  BCard, BSpinner, BCardBody,
} from 'bootstrap-vue'

export default {
  name: 'SidepanelCourses',
  components: {
    BCard,
    BSpinner,
    BCardBody,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormInput,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    courses: {
      type: Array,
      required: true,
    },
    courseSelected: {
      type: Function,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    search: null,
  }),
  methods: {
    searchUpdated() {
      this.$emit('onSearch', this.search)
    },
  },
}
</script>

<style scoped lang="scss">

</style>
