<template>
  <div :class="{isMobile}">
    <div
      v-if="backdrop"
      class="sidepanel-courses-backdrop"
      :class="{show: show}"
      @click="hidePanel()"
    />
    <b-card
      no-body
      :class="`${panelClass} ${show ? 'show' : ''}`"
    >
      <header-buttons
        :tabs="[{
          name: 'assigned',
          title: 'Назначенные',
          icon: 'StartIcon',
          type: assignedType,
        }, ...tabs]"
        :course-type-changed="(type) => selectedType = type"
        :hide-panel="hidePanel"
        :current-steps="showCurrentSteps"
        :show-current-steps="(bool) => showCurrentSteps = bool"
        :persistent="persistent"
        :hide-close-button="isMobile"
      />

      <sidepanel-breadcrumbs
        :current-lesson="showCurrentSteps"
        :course="selectedCourse"
        :theme="selectedTheme"
        :remove-course="() => { selectedCourse = null; selectedTheme = null }"
        :remove-theme="() => { selectedTheme = null }"
        :selected-type="selectedType"
      />

      <div class="list-container">
        <div v-if="showCurrentSteps">
          <sidepanel-lesson-steps
            :steps="currentSteps"
            :selected-tab="currentLessonTab"
          />
        </div>
        <div v-else-if="selectedType === userFavoritesType">
          <sidepanel-lesson-steps
            :steps="favoriteSteps"
            :selected-tab="currentLessonTab"
          />
        </div>
        <div v-else-if="selectedType === assignedType">
          <sidepanel-lesson-steps
            :steps="sessionSteps"
            :selected-tab="currentLessonTab"
          />
        </div>
        <div v-else>
          <sidepanel-courses
            v-if="!selectedCourse"
            :courses="courses"
            :course-selected="selectCourse"
            :loading="loadingCourses"
            :editable="editable"
            @onSearch="(search) => getCourses(search)"
          />

          <sidepanel-themes
            v-else-if="!selectedTheme"
            :themes="themes"
            :loading="loadingThemes"
            :editable="editable"
            :course="selectedCourse"
            @themeSelected="selectTheme"
          />

          <sidepanel-lessons
            v-else
            :course="selectedCourse"
            :lessons="lessons"
            :lesson-selected="selectLesson"
            :loading="loadingLessons"
            :editable="editable"
          />
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import HeaderButtons from '@/components/stream-courses/course/sidepanel/HeaderButtons.vue'
import SidepanelCourses from '@/components/stream-courses/course/sidepanel/SidepanelCourses.vue'
import SidepanelThemes from '@/components/stream-courses/course/sidepanel/SidepanelThemes.vue'
import SidepanelBreadcrumbs from '@/components/stream-courses/course/sidepanel/SidepanelBreadcrumbs.vue'
import SidepanelLessons from '@/components/stream-courses/course/sidepanel/SidepanelLessons.vue'
import SidepanelLessonSteps from '@/components/stream-courses/course/sidepanel/lesson/SidepanelLessonSteps.vue'

export default {
  name: 'SidePanelCourseSelection',
  components: {
    SidepanelLessonSteps,
    SidepanelLessons,
    SidepanelBreadcrumbs,
    SidepanelThemes,
    SidepanelCourses,
    HeaderButtons,
    BCard,
  },
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    backdrop: {
      type: Boolean,
      default: false,
    },
    panelClass: {
      type: String,
      default: '',
    },
    editable: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loadingCourses: false,
    loadingThemes: false,
    loadingLessons: false,
    courses: [],
    themes: [],
    lessons: [],
    sessionSteps: [],
    favoriteSteps: [],
    selectedType: null,
    selectedCourse: null,
    selectedTheme: null,
    selectedLesson: null,
    showCurrentSteps: false,
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
      session: 'videoStream/get_session',
      currentSteps: 'videoStream/get_steps',
      currentLessonTab: 'videoStream/get_current_lesson_tab',
      tabs: 'course/get_courses_types',
      userCourseType: 'course/get_course_user_type',
      userFavoritesType: 'course/get_course_favorites_type',
      assignedType: 'course/get_course_assigned_type',
    }),
  },
  watch: {
    show(newValue) {
      if (newValue) {
        if (this.selectedType === this.assignedType) this.getSessionSteps()
        else if (this.selectedType === this.userFavoritesType) this.getFavoriteSteps()
        else this.getCourses()
      }
    },
    selectedType() {
      if (this.show) {
        this.resetSelected()
        if (this.selectedType === this.assignedType) this.getSessionSteps()
        else if (this.selectedType === this.userFavoritesType) this.getFavoriteSteps()
        else this.getCourses()
      }
    },
  },
  mounted() {
    if (this.session?.id) this.getSessionSteps()
  },
  methods: {
    async getCourses(search = null) {
      this.loadingCourses = true
      const params = {
        type: this.selectedType,
        name: search,
      }
      if (this.selectedType === this.userCourseType) params.user_id = this.user.id
      const { items } = await this.$http.get('/courses', {
        params,
      })
      this.courses = items
      this.loadingCourses = false
    },
    async getThemes() {
      if (!this.selectedCourse) return
      this.loadingThemes = true
      const { items } = await this.$http.get(`/themes/index?course_id=${this.selectedCourse.id}`)
      this.themes = items
      this.loadingThemes = false
    },
    async getLessons() {
      if (!this.selectedTheme) return
      this.loadingLessons = true
      const { items } = await this.$http.get(`/lessons/index?theme_id=${this.selectedTheme.id}`)
      this.lessons = items
      this.loadingLessons = false
    },
    async getSessionSteps() {
      if (!this.session?.id) return
      this.loadingLessons = true
      const { items } = await this.$http.get('/session-steps/index', {
        params: {
          expand: 'step.stepElements.image,exercise.exercisesContents',
          session_id: this.session.id,
          sort: '+order',
        },
      })

      this.sessionSteps = items

      if (this.sessionSteps.length && !this.currentSteps?.length) {
        this.$videoStreamEventBus.$emit('set-steps', { steps: this.sessionSteps, stepIndex: 0 })
        this.$eventBus.$emit('select-step', this.sessionSteps[0])
      }
      this.loadingLessons = false
    },
    async getFavoriteSteps() {
      this.loadingLessons = true
      const { items } = await this.$http.get('/favorite-steps/current-user-index', {
        params: {
          expand: 'lessonStep.stepElements.image',
        },
      })

      this.favoriteSteps = items.map(favorite => ({ ...favorite.lessonStep }))
      this.loadingLessons = false
    },
    selectCourse(course) {
      this.selectedCourse = course
      this.getThemes()
    },
    selectTheme(theme) {
      this.selectedTheme = theme
      this.getLessons()
    },
    selectLesson(lesson) {
      this.selectedLesson = lesson
    },
    hidePanel() {
      this.$videoStreamEventBus.$emit('select-course')
    },
    resetSelected() {
      this.selectedCourse = null
      this.selectedTheme = null
    },
  },
}
</script>

<style scoped lang="scss">
  .sidepanel-courses-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,0.7);
    z-index: 99;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.3s, opacity 0.3s ease-in-out;

    &.show {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
    }
  }

  .isMobile {
    height: 70vh;
    overflow: auto;

    .card {
      margin-bottom: 0;
      min-height: 100%;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: 1px solid #00000017;
      z-index: 99;
    }

    .sidepanel-courses-backdrop {
      z-index: 0;

      &.show {
        opacity: 0.4;
      }
    }
  }

</style>
