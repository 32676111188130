<template>
  <div>
    <div
      v-if="!isStream"
      class="video-stream-start-screen"
    >
      <div class="d-flex justify-content-center align-items-center h-100">
        <div class="text-center">
          <b-spinner
            variant="primary"
            type="grow"
            size="56"
          />
          <p class="mt-2 font-medium-3 font-weight-bold text-uppercase text-primary">
            <span v-if="closing">Завершение конференции...</span>
            <span v-else-if="isTeacher">Создание комнаты...</span>
            <span v-else>Подключение...</span>
          </p>
        </div>
      </div>
    </div>
    <div v-else-if="!isMobile">
      <b-row class="no-gutters">
        <b-col
          class="col-12"
          :class="{'col-md-7 col-xl-8': !isBoardFullscreen}"
        >
          <stream-grid
            :board-enabled="isBoard"
            :streams="streams"
            :swap-cams="(to) => swapCams(to)"
            :screenshot="screenshotUrl"
            :is-fullscreen="isBoardFullscreen"
            @onFullscreenToggle="isBoardFullscreen = !isBoardFullscreen"
          />
        </b-col>
        <b-col
          class="col-12 col-md-5 col-xl-4"
          :class="{'d-none': isBoardFullscreen}"
        >
          <side-panel-course-selection
            :show="showCourseSelect"
            backdrop
            panel-class="sidepanel-courses-absolute"
          />
          <video-stream-actions
            v-if="ownStream"
            :stream="ownStream"
            :show-course-select="showCourseSelect"
            :is-board="isBoard"
            :is-shared-screen="rtc.extra.screenSharing"
            @onScreenShare="shareScreen"
          />
        </b-col>
      </b-row>
    </div>
    <div
      v-else
      class="mobile-stream-container"
    >
      <MobileStreamGrid
        :board-enabled="isBoard"
        :streams="streams"
        :swap-cams="(to) => swapCams(to)"
        :screenshot="screenshotUrl"
      />

      <b-collapse
        v-model="showCourseSelect"
        style="position: fixed; bottom: 56px; left: 0; right: 0"
      >
        <side-panel-course-selection
          is-mobile
          backdrop
          :show="showCourseSelect"
        />
      </b-collapse>

      <BottomBar
        :show-course-select="showCourseSelect"
        :is-board="isBoard"
        :stream="ownStream"
      />
    </div>

    <end-session-confirm ref="endSessionConfirm" />

    <user-media-request-hint
      :show="showMediaRequestHint"
      :not-allowed="userMediaNotAllowed"
      :no-mic="noMicFound"
      :close-hint="() => showMediaRequestHint = false"
    />
  </div>
</template>

<script>
import {
  BRow, BCol, BSpinner, BCollapse,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import { v4 } from 'uuid'
import { $themeBreakpoints } from '@themeConfig'
import {
  adminRole, studentRole, teacherRole, userRoles,
} from '@/store/user'
import StreamGrid from '@/components/video-stream/StreamGrid.vue'
import { createRTC, getUserMediaSettings, RTCGetConstraints } from '@/libs/rtc'
import VideoStreamActions from '@/components/video-stream/VideoStreamActions.vue'
import EndSessionConfirm from '@/components/page-elements/modals/EndSessionConfirm.vue'
import UserMediaRequestHint from '@/components/video-stream/actions/UserMediaRequestHint.vue'
import mediaAccessGranted from '@/components/video-stream/MediaDevicesAccessGranted'
import SidePanelCourseSelection from '@/components/stream-courses/course/SidePanelCourseSelection.vue'
import {
  HOMETASK_STATUS_ACTIVE, SESSION_STATUS_ONLINE,
  SESSION_STATUS_PASSED,
} from '@/shared/constants'
import { toast } from '@/helpers'
import BottomBar from '../../components/video-stream/mobile/BottomBar.vue'
import MobileStreamGrid from '../../components/video-stream/mobile/MobileStreamGrid.vue'

export default {
  components: {
    MobileStreamGrid,
    BottomBar,
    SidePanelCourseSelection,
    UserMediaRequestHint,
    EndSessionConfirm,
    VideoStreamActions,
    StreamGrid,
    BRow,
    BCol,
    BSpinner,
    BCollapse,
  },
  beforeRouteLeave(to, from, next) {
    if (this.isStream) {
      toast({
        title: 'Действие отменено', text: 'Пожалуйста, завершите звонок для выхода', type: 'warning', position: 'top-left',
      })
    } else {
      next()
    }
  },
  data: () => ({
    isStream: false,
    streams: [],
    rtc: null,
    streamParams: null,
    isBoard: false,
    isBoardFullscreen: false,
    showMediaRequestHint: false,
    userMediaNotAllowed: false,
    startingStream: false,
    screenshotUrl: '',
    showCourseSelect: false,
    showCurrentSteps: false,
    boardsData: null,
    noMicFound: false,
    noCamFound: false,
    closing: false,
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
      userName: 'user/get_user_full_name',
      session: 'videoStream/get_session',
    }),
    userRole() {
      return this.user ? userRoles[this.user.role] : ''
    },
    isAdmin() {
      return this.userRole === adminRole
    },
    isTeacher() {
      return this.userRole === teacherRole
    },
    isStudent() {
      return this.userRole === studentRole
    },
    queryRoomId() {
      return this.$route.params.room_id
    },
    origin() {
      return this.$route.query.origin
    },
    sessionId() {
      return this.$route.params.session_id
    },
    ownStream() {
      return this.streams.find(stream => stream?.user?.user_id === this.user.id)
    },
    isMobile() {
      return window.innerWidth <= $themeBreakpoints.md
    },
  },
  beforeDestroy() {
    this.$videoStreamEventBus.$off()
  },
  async created() {
    const {
      sessionId, queryRoomId, isTeacher, isStudent, forbidden, user,
    } = this

    if (queryRoomId) {
      await this.startOrCreateStream(queryRoomId)
      return
    }
    const session = await this.getSession(sessionId)
    const {
      teacher_id: sessionTeacherId, sessionStudents, teacherRooms, status,
    } = session

    const isCorrectTeacher = !isTeacher || (isTeacher && user.id === sessionTeacherId)
    const isCorrectStudent = !isStudent || (sessionStudents.map(sessionStudent => sessionStudent.student_id).includes(user.id) && status === SESSION_STATUS_ONLINE)

    if (isCorrectTeacher && isCorrectStudent) await this.startOrCreateStream(teacherRooms[0]?.room_id)
    else forbidden()
  },
  methods: {
    ...mapActions({
      setSteps: 'videoStream/setLessonSteps',
      setExercises: 'videoStream/setLessonExercises',
      resetLessonStepsState: 'videoStream/resetState',
      getSession: 'videoStream/getSession',
      updateSession: 'videoStream/updateSession',
      saveSession: 'videoStream/saveSession',
      setCurrentLessonTab: 'videoStream/setCurrentLessonTab',
      startSession: 'videoStream/startSession',
      finishSession: 'videoStream/endSession',
      clearSession: 'videoStream/clearSession',
    }),
    forbidden() {
      this.destroyStream()
    },
    async checkMicAndCam() {
      const userDevices = {
        audioinput: [],
        videoinput: [],
      }
      await navigator.mediaDevices.enumerateDevices()
        .then(devices => {
          devices.forEach(device => {
            const { label, kind, deviceId } = device
            if (userDevices[kind]) {
              userDevices[kind].push({ label, deviceId })
            }
          })
        })

      const mics = userDevices.audioinput
      const cams = userDevices.videoinput

      if (!mics.length) {
        this.showMediaRequestHint = true
        this.userMediaNotAllowed = true
        this.noMicFound = true
      }
      if (!cams.length) this.noCamFound = true
    },
    async requestDevicePermissions() {
      await this.checkMicAndCam()

      return new Promise((resolve, reject) => {
        if (navigator.mediaDevices.getUserMedia) {
          mediaAccessGranted().catch(async () => {
            this.showMediaRequestHint = true
          })

          navigator.mediaDevices.getUserMedia({ audio: true, video: !this.noCamFound })
            .then(stream => {
              this.showMediaRequestHint = false
              stream.getTracks().forEach(track => {
                track.stop()
              })
              resolve(true)
            }).catch(err => {
              this.showMediaRequestHint = true
              this.userMediaNotAllowed = true
              reject(err)
            })
        } else {
          this.showMediaRequestHint = false
        }
      })
    },
    async initRTC() {
      const { audio, video } = await RTCGetConstraints()
      const connection = await createRTC({ audio, video })
      this.rtc = connection

      const { rtc, $videoStreamEventBus: eventBus } = this

      eventBus.$off()

      eventBus.$on('select-course', () => {
        this.showCourseSelect = !this.showCourseSelect
        if (this.isBoard && this.showCourseSelect) this.isBoard = false
      })

      eventBus.$on('stream-set-mode', mode => {
        this.streamParams.mode = mode
        rtc.send({ type: 'socket-stream-set-mode', data: { mode } })
      })

      eventBus.$on('stream-set-step', params => {
        this.streamParams = { ...params }
        rtc.send({ type: 'socket-stream-set-step', data: this.streamParams })
      })

      eventBus.$on('stream-set-step-index', index => {
        this.streamParams.index = index
        rtc.send({ type: 'socket-stream-set-step-index', data: { index } })
      })

      eventBus.$on('stream-toggle-board', () => {
        this.isBoard = !this.isBoard
        if (this.isBoard && this.showCourseSelect) this.showCourseSelect = false
        rtc.send({ type: 'socket-stream-toggle-board', data: { value: this.isBoard } })
      })

      eventBus.$on('stream-take-screenshot', () => {
        rtc.send({ type: 'socket-stream-take-screenshot', data: {} })
      })

      eventBus.$on('stream-send-screenshot-to-teacher', data => {
        rtc.send({ type: 'socket-stream-send-screenshot-to-teacher', data })
      })

      eventBus.$on('sync-boards-state', data => {
        this.boardsData = { ...data }
        rtc.send({ type: 'sync-boards-state', data })
      })

      eventBus.$on('sync-small-board-collapse', data => {
        rtc.send({ type: 'sync-small-board-collapse', data })
      })

      eventBus.$on('sync-foreign-small-board', data => {
        rtc.send({ type: 'sync-small-board', data })
      })

      eventBus.$on('sync-foreign-big-board', data => {
        rtc.send({ type: 'sync-board', data })
      })

      eventBus.$on('stream-set-exercise', params => {
        this.streamParams = { ...params }
        rtc.send({ type: 'socket-stream-set-exercise', data: this.streamParams })
      })

      eventBus.$on('stream-exercise-answer-selected', answer => {
        if (this.streamParams) this.streamParams.currentExerciseSelectedAnswers.push(answer)
        rtc.send({ type: 'socket-stream-exercise-answer-selected', data: { answer, senderId: this.user.id } })
      })

      eventBus.$on('stream-exercise-answer-removed', answer => {
        if (this.streamParams) this.streamParams.currentExerciseSelectedAnswers.splice(this.streamParams.currentExerciseSelectedAnswers.indexOf(answer), 1)
        rtc.send({ type: 'socket-stream-exercise-answer-removed', data: { answer, senderId: this.user.id } })
      })

      eventBus.$on('stream-exercise-answered', () => {
        if (this.streamParams) this.streamParams.currentExerciseAnswered = true
        rtc.send({ type: 'socket-stream-exercise-answered', data: { senderId: this.user.id } })
      })

      eventBus.$on('stream-exercise-next', data => {
        if (this.streamParams) {
          this.streamParams.currentExerciseAnswered = false
          this.streamParams.currentExerciseSelectedAnswers = []
          this.streamParams.index = data.index
          this.streamParams.totalCorrect = data.totalCorrect
        }
        rtc.send({
          type: 'socket-stream-exercise-next',
          data: {
            index: data.index,
            totalCorrect: data.totalCorrect,
            senderId: this.user.id,
          },
        })
      })

      eventBus.$on('stream-exercise-mode-switched', mode => {
        if (this.streamParams) this.streamParams.exercisesMode = mode
        rtc.send({ type: 'socket-stream-exercise-mode-switched', data: { mode } })
      })

      eventBus.$on('stream-end', async () => {
        await this.endSession()
      })

      return connection
    },
    isScreenStream(stream) {
      if (!stream.idInstance) return false

      try {
        return JSON.parse(stream.idInstance)?.isScreen
      } catch (e) {
        return stream.idInstance.isScreen
      }
    },
    shareScreen() {
      const myScreenShare = this.streams.find(stream => stream.user.user_id === this.user.id && stream.user.screenSharing)

      if (myScreenShare) {
        const rtcInstance = this.rtc.attachStreams.find(stream => stream.id === myScreenShare.id)
        if (rtcInstance) {
          rtcInstance.getTracks().forEach(track => track.stop())
        }
      } else {
        this.rtc.addStream({
          screen: true,
          oneway: true,
        })
      }
    },
    async streamWithGainNode(stream) {
      const audioTrack = stream.getAudioTracks()[0]
      const ctx = new AudioContext()
      const tempStream = new MediaStream([audioTrack])
      const src = ctx.createMediaStreamSource(tempStream)
      const dst = ctx.createMediaStreamDestination()
      const gainNode = ctx.createGain()

      gainNode.gain.value = localStorage.getItem('gain') || 1
      src.connect(gainNode)
      gainNode.connect(dst)

      const newTrack = dst.stream.getAudioTracks()[0]
      stream.addTrack(newTrack)
      stream.removeTrack(audioTrack)

      return {
        src: stream,
        gainNode,
        tempStream,
      }
    },
    async startOrCreateStream(roomId = null) {
      this.startingStream = true
      this.requestDevicePermissions()
        .then(async () => {
          if (!this.rtc) await this.initRTC()
          await this.rtc.checkPresence(roomId, async isRoomExist => {
            if (!isRoomExist) await this.startStream(roomId)
            else await this.connectStream(roomId)
          })
          if (this.queryRoomId) await this.$chatsHttp.get(`/rooms/${roomId}/online`)
        })
        .catch(() => {
          this.startingStream = false
        })
    },
    async startStream(existingRoomId = null) {
      this.startingStream = true
      const id = existingRoomId ?? v4()
      this.rtc.onstream = this.onstreamCreated
      this.rtc.onstreamended = this.onstreamEnded
      this.rtc.onmessage = this.onstreamMessage
      this.rtc.onMediaError = this.onMediaError
      this.rtc.extra = {
        user_id: this.user.id,
        user_name: this.userName,
      }
      this.rtc.open(id)

      const { sessionId } = this
      if (sessionId) {
        await this.startSession({ sessionId: parseInt(sessionId, 10), roomId: id }).catch(() => {
          this.forbidden()
        })
        await this.$chatsHttp.get(`/sessions/${this.sessionId}/online`)
      }
      this.startingStream = false
    },
    async onMediaError(e) {
      /* eslint-disable no-console */
      console.error(e)
    },
    async connectStream(id) {
      this.startingStream = true
      this.rtc.onstream = this.onstreamCreated
      this.rtc.onstreamended = this.onstreamEnded
      this.rtc.onmessage = this.onstreamMessage
      this.rtc.extra = {
        user_id: this.user.id,
        user_name: this.userName,
        isJoined: true,
      }
      await this.rtc.join(id)
      this.startingStream = false
    },
    async onstreamMessage({ data }) {
      const { type, data: params } = data

      if (this.isStudent || this.isAdmin) {
        if (!this.isAdmin && type === 'socket-teacher-stream-end-event') {
          await this.destroyStream()
        }

        const streamStepChannels = ['socket-stream-set-step']
        if (this.rtc) streamStepChannels.push(`socket-stream-set-step-${this.rtc.userid}`)
        if (streamStepChannels.includes(type)) {
          let steps = []
          const stepIndex = params.index
          if (params.id) {
            const { items } = await this.$http.get(`/lesson-steps/index?lesson_id=${params.id}&expand=stepElements.image`)
            steps = [...items]
          } else {
            const { items } = await this.$http.get('/session-steps', {
              params: {
                session_id: this.sessionId,
                sort: '+order',
                expand: 'step.stepElements.image,exercise.exercisesContents',
              },
            })
            steps = [...items]
          }
          this.$videoStreamEventBus.$emit('set-steps', { steps, stepIndex })
        }

        if (type === 'socket-stream-set-step-index') {
          this.$videoStreamEventBus.$emit('socket-set-step-index', params.index)
        }

        const streamExerciseChannels = ['socket-stream-set-exercise']
        if (this.rtc) streamExerciseChannels.push(`socket-stream-set-exercise-${this.rtc.userid}`)
        if (streamExerciseChannels.includes(type)) {
          const { items } = await this.$http.get(`/exercises-content/index?exercises_id=${params.exerciseId}`)
          this.setExercises(items)
          this.$videoStreamEventBus.$emit('set-exercise', { exerciseId: params.exerciseId, exerciseIndex: params.index })
          this.$nextTick(() => {
            this.$videoStreamEventBus.$emit('lesson-exercises-set-params', params)
          })
        }

        if (type === 'socket-stream-exercise-mode-switched') {
          this.$videoStreamEventBus.$emit('lesson-exercises-mode-switched', params.mode)
        }

        if (type === 'socket-stream-set-mode') {
          this.$videoStreamEventBus.$emit('socket-set-mode', params.mode)
        }

        if (type === 'socket-stream-take-screenshot') {
          this.takeScreenshot()
        }

        if (type === 'sync-boards-state') {
          this.$videoStreamEventBus.$emit('sync-boards-state-remote', params)
        }
      }

      if (this.isTeacher || this.isAdmin) {
        if (!this.isAdmin && type === 'socket-teacher-stream-end-event') {
          await this.destroyStream()
        }

        if (type === 'socket-stream-exercise-answer-selected') {
          const { index } = params
          this.$videoStreamEventBus.$emit('lesson-test-set-answer-index', index)
        }

        if (type === 'socket-stream-send-screenshot-to-teacher') {
          this.screenshotUrl = params
        }

        // if (type === 'socket-student-left-stream') {
        //   const studentStreamId = params.streamId
        //   this.streams = this.streams.filter(stream => stream.id !== studentStreamId)
        //   toast({
        //     title: 'Участник вышел', text: `Пользователь ${params.username} покинул комнату`, type: 'info', position: 'top-left',
        //   })
        //   if (this.streams.length === 1) this.swapCams(this.streams[0])
        // }
      }

      if (type === 'socket-stream-exercise-answer-selected') {
        if (this.streamParams) this.streamParams.currentExerciseSelectedAnswers.push(params.answer)
        this.$videoStreamEventBus.$emit('lesson-exercises-answer-selected', params)
      }

      if (type === 'socket-stream-exercise-answer-removed') {
        if (this.streamParams) this.streamParams.currentExerciseSelectedAnswers.splice(this.streamParams.currentExerciseSelectedAnswers.indexOf(params.answer), 1)
        this.$videoStreamEventBus.$emit('lesson-exercises-answer-removed', params)
      }

      if (type === 'socket-stream-exercise-answered') {
        if (this.streamParams) this.streamParams.currentExerciseAnswered = true
        this.$videoStreamEventBus.$emit('lesson-exercises-answered', params)
      }

      if (type === 'socket-stream-exercise-next') {
        if (this.streamParams) {
          this.streamParams.currentExerciseAnswered = false
          this.streamParams.currentExerciseSelectedAnswers = []
          this.streamParams.index = params.index
          this.streamParams.totalCorrect = params.totalCorrect
        }
        this.$videoStreamEventBus.$emit('lesson-exercises-next', params)
      }

      if (type === 'sync-small-board') {
        this.$videoStreamEventBus.$emit('sync-own-small-board', params)
      }
      if (type === 'sync-small-board-collapse') {
        this.$videoStreamEventBus.$emit('sync-own-small-board-collapse', params)
      }
      if (type === 'sync-board') {
        this.$videoStreamEventBus.$emit('sync-own-big-board', params)
      }

      if (type === 'socket-stream-toggle-board') {
        this.isBoard = params.value
      }
    },
    async onstreamCreated(event) {
      const {
        type, stream, streamid, extra, userid,
      } = event

      /* eslint-disable no-console */
      // console.log('created', event.type, userid, streamid)

      if (type === 'local') {
        const isScreen = this.isScreenStream(stream)
        const streamData = {
          id: streamid,
          userid,
          srcObject: stream,
          user: { ...extra, screenSharing: isScreen },
          local: true,
          main: false,
        }
        if (!isScreen) {
          const { src, gainNode, tempStream } = await this.streamWithGainNode(stream)
          streamData.srcObject = src
          streamData.tempSrcObject = tempStream
          streamData.gainNode = gainNode
          streamData.main = true
        }
        this.streams.push(streamData)

        if (isScreen) {
          this.rtc.extra = {
            ...this.rtc.extra,
            screenSharing: true,
          }
          this.$nextTick(() => {
            this.swapCams(streamData)
          })
        }
      } else if (type === 'remote') {
        const isScreen = this.streams.find(st => st.userid === userid)
        const newViewer = {
          id: streamid,
          userid,
          srcObject: stream,
          user: { ...extra, screenSharing: isScreen },
        }
        this.streams.push(newViewer)

        this.$nextTick(() => {
          this.swapCams(newViewer)
        })

        if (isScreen) {
          toast({
            title: 'Демонстрация экрана', text: `Пользователь ${extra.user_name} начал демонстрацию экрана`, type: 'info', position: 'top-left',
          })
        } else if (extra.isJoined) {
          toast({
            title: 'Новый участник', text: `Пользователь ${extra.user_name} присоединился к комнате`, type: 'info', position: 'top-left',
          })
        }
      }

      this.isStream = true
      this.startingStream = false
    },
    async destroyStream(shutDown = false) {
      this.closing = true
      this.isBoard = false

      if (this.rtc) {
        this.isStream = false

        if (shutDown) {
          // disconnect all users
          this.rtc.send({
            type: 'socket-teacher-stream-end-event',
            data: {},
          })

          this.rtc.getAllParticipants().forEach(pid => {
            this.rtc.disconnectWith(pid)
          })
        }

        // stop livestream
        this.rtc.attachStreams.forEach(localStream => {
          localStream.getTracks().forEach(async track => {
            await track.stop()
          })
        })
      }

      this.streamParams = null

      this.resetLessonStepsState()
      await this.clearSession()

      if (this.$route.name !== 'cabinet.sessions') {
        if (this.origin) {
          this.$router.push({ name: this.origin }).catch()
        } else if (this.queryRoomId) {
          this.$router.push({ name: this.isAdmin ? 'cabinet.admin.chat' : 'cabinet.chat' }).catch()
        } else {
          this.$router.push({ name: this.isAdmin ? 'cabinet.admin.chat' : 'cabinet.sessions' }).catch()
        }
      }
    },
    async onstreamEnded(event) {
      const {
        type, streamid, extra, stream: eventStream,
      } = event
      /* eslint-disable no-console */
      // console.log('ended', type, userid, streamid)
      if (!this.streams.find(s => s.id === streamid)) return

      if (event.type === 'remote' && this.isStream) {
        const isScreen = this.streams.find(st => st.id === streamid && st.user?.screenSharing)
        this.swapCams(this.ownStream)
        if (isScreen) {
          toast({
            title: 'Демонстрация экрана', text: `Пользователь ${extra.user_name} прекратил демонстрацию экрана`, type: 'info', position: 'top-left',
          })
        } else {
          toast({
            title: 'Участник вышел', text: `Пользователь ${extra.user_name} покинул комнату`, type: 'info', position: 'top-left',
          })
        }

        if (!isScreen) this.rtc.disconnectWith(event.userid)
      } else if (type === 'local') {
        const isScreen = this.isScreenStream(eventStream)
        if (isScreen) {
          this.rtc.extra = {
            ...this.rtc.extra,
            screenSharing: false,
          }
        }
        this.streams.forEach(localStream => {
          const { srcObject, tempSrcObject, id } = localStream
          if (id === streamid) {
            srcObject.getTracks().forEach(track => track.stop())
            if (tempSrcObject) tempSrcObject.getTracks().forEach(track => track.stop())
          }
        })

        if (this.streams.length) {
          const firstRemoteStream = this.streams.find(stream => stream.user.user_id !== this.user.id)
          this.swapCams(firstRemoteStream ?? this.streams[0])
        }

        if (!isScreen) this.rtc.disconnectWith(event.userid)
      }

      this.streams = [...this.streams.filter(s => s.id !== event.streamid)]
      if (this.rtc?.extra && this.streams?.length === 1) {
        this.rtc.extra.isJoined = false
      }

      if (!this.streams?.length) {
        this.rtc.closeSocket()
        this.rtc.leave()
        this.rtc = null
        /* eslint-disable no-console */
        // console.log('closed')
      }
    },
    async endSession() {
      if (this.isTeacher && !this.queryRoomId) {
        this.$refs.endSessionConfirm.open()
          .then(async promise => {
            if (this.session) {
              if (promise.result) {
                const session = await this.finishSession({ sessionId: this.session.id })
                if (session.status === SESSION_STATUS_PASSED) await this.activateRelatedHometasks()
              }
              this.$chatsHttp.get(`/sessions/${this.session.id}/offline`)
            }
            await this.destroyStream(this.isTeacher || this.isAdmin)
          })
      } else {
        await this.destroyStream(this.isAdmin)
      }
    },
    takeScreenshot() {
      const userMediaSettings = getUserMediaSettings()
      navigator.mediaDevices.getUserMedia({ video: { deviceId: userMediaSettings.camera } })
        .then(stream => {
          const mediaStreamTrack = stream.getVideoTracks()[0]
          const imageCapture = new ImageCapture(mediaStreamTrack)
          imageCapture.takePhoto()
            .then(blob => {
              const reader = new FileReader()
              reader.readAsDataURL(blob)
              reader.onloadend = () => {
                this.$videoStreamEventBus.$emit('stream-send-screenshot-to-teacher', reader.result)
              }
            })
            .catch(() => { })
        })
        .catch(() => { })
    },
    swapCams(swapTo) {
      this.streams = this.streams.map(stream => {
        const str = stream
        str.main = str.id === swapTo.id
        return str
      })
    },
    async activateRelatedHometasks() {
      const { items: sessionStudents } = await this.$http.get('/session-students', {
        params: {
          session_id: this.session.id,
          expand: 'sessionStudentHomeTasks',
        },
      })
      const sessionStudentHomeTasks = sessionStudents.map(sessionStudent => ({
        student_id: sessionStudent.student_id,
        hometaskIds: sessionStudent.sessionStudentHomeTasks.map(task => task.home_task_id),
      }))
      const hometasks = []
      sessionStudentHomeTasks.forEach(({ student_id, hometaskIds }) => {
        hometaskIds.forEach(id => {
          hometasks.push({
            id,
            student_id,
          })
        })
      })

      await Promise.all(hometasks.map(async ({ id, student_id }) => {
        await this.$http.put(`/home-tasks/update?id=${id}`, {
          status: HOMETASK_STATUS_ACTIVE,
          student_id,
        })
        return id
      }))
    },
  },
}
</script>

<style lang="scss">
.grid-wrapper {
  display: flex;
  justify-content: space-around;
  flex-flow: row wrap;
  align-items: center;

  & > div:first-child {
    width: 60%;
  }

  & > div:last-child {
    width: 40%;
  }
}

.main-menu-btn {
  position: fixed;
  top: 15px;
  left: 19px;
  z-index: 92;
}

.video-stream-start-screen {
  height: calc(100vh - 200px);
}

.app-content-margin-0 {
  margin: 0 !important;
}

.actions-panel {
  transition: 0.3s ease-in;

  &.panel-hidden {
    width: 0;
  }
}

</style>

<style scoped lang="scss">

</style>
