<template>
  <b-card
    class="mb-0 rounded-0"
    no-body
    style="position: sticky; bottom: 0"
  >
    <b-collapse v-model="additionalMenu">
      <b-card
        class="mb-0 rounded-0 py-50"
        no-body
      >
        <b-button
          variant="flat-danger"
          block
          @click="$videoStreamEventBus.$emit('stream-end')"
        >
          Выйти
        </b-button>
      </b-card>
      <div class="thin-divider" />
    </b-collapse>

    <div
      :key="updateButtons"
      class="d-flex align-items-center justify-content-around p-50"
    >
      <b-button
        v-if="audioTrack"
        id="settings-popover"
        title="Настройки микрофона"
        variant="flat-primary"
        class="btn-icon "
        @click="toggleMic"
      >
        <feather-icon
          size="20"
          :icon="audioTrack.enabled ? 'MicIcon' : 'MicOffIcon'"
          :class="{'text-danger': !audioTrack.enabled}"
        />
      </b-button>

      <b-button
        v-if="videoTrack"
        class="btn-icon "
        variant="flat-primary"
        :title="$t('page.video-stream.grid.toggle_camera')"
        @click="toggleVideo"
      >
        <feather-icon
          size="20"
          :icon="videoTrack && videoTrack.enabled ? 'VideoIcon' : 'VideoOffIcon'"
          :class="{'text-danger': !videoTrack || !videoTrack.enabled}"
        />
      </b-button>

      <b-button
        class="btn-icon "
        :variant="isBoard ? 'primary' : 'flat-secondary'"
        @click="$videoStreamEventBus.$emit('stream-toggle-board')"
      >
        <feather-icon
          size="20"
          icon="AirplayIcon"
        />
      </b-button>

      <b-button
        v-if="isTeacher"
        class="btn-icon "
        :variant="showCourseSelect ? 'primary' : 'flat-secondary'"
        @click="$videoStreamEventBus.$emit('select-course')"
      >
        <feather-icon
          size="20"
          icon="BookOpenIcon"
        />
      </b-button>

      <b-button
        class="btn-icon "
        variant="flat-secondary"
        @click="additionalMenu = !additionalMenu"
      >
        <feather-icon
          size="20"
          icon="MoreVerticalIcon"
        />
      </b-button>
    </div>
  </b-card>
</template>

<script>
import { BCard, BButton, BCollapse } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { teacherRole, userRoles } from '../../../store/user'

export default {
  name: 'BottomBar',
  components: {
    BCard, BButton, BCollapse,
  },
  props: {
    isBoard: {
      type: Boolean,
      required: true,
    },
    showCourseSelect: {
      type: Boolean,
      required: true,
    },
    stream: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    showSettingsPopover: false,
    micGain: 0.5,
    updateButtons: 0,
    micGainMarks: [0, 1],

    additionalMenu: false,
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
    }),
    userRole() {
      return this.user ? userRoles[this.user.role] : null
    },
    isTeacher() {
      return this.userRole === teacherRole
    },
    streamSrc() {
      return this.stream.srcObject
    },
    isSharedScreen() {
      return this.stream.user.screenSharing
    },
    videoTrack() {
      const tracks = this.streamSrc.getVideoTracks()
      if (!tracks.length) return null
      return tracks[0]
    },
    audioTrack() {
      const tracks = this.streamSrc.getAudioTracks()
      if (!tracks.length) return null
      return tracks[0]
    },
  },
  watch: {
    micGain(newValue) {
      this.modifyGain(newValue)
    },
  },
  mounted() {
    this.micGain = localStorage.getItem('gain') || 1
  },
  methods: {
    toggleVideo() {
      if (this.videoTrack) {
        this.videoTrack.enabled = !this.videoTrack.enabled
        this.updateButtons += 1
      }
    },
    toggleMic() {
      if (this.audioTrack) {
        this.audioTrack.enabled = !this.audioTrack.enabled
        this.updateButtons += 1
      }
    },
    modifyGain(gain) {
      if (gain === 0) {
        this.audioTrack.enabled = false
      } else {
        this.audioTrack.enabled = true
      }
      localStorage.setItem('gain', gain)
      this.stream.gainNode.gain.value = gain
    },
    hideModals() {
      this.additionalMenu = false
    },
  },
}
</script>

<style scoped>

</style>
