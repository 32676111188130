<template>
  <div
    class="mobile-stream-grid"
    :class="{'board-enabled': boardEnabled}"
  >

    <stream-boards
      class="stream-board"
      :enabled="boardEnabled"
      :class="{enabled: boardEnabled}"
      :background-color="backgroundColor"
      tools-position="top"
    />

    <!--   MAIN CAM    -->
    <stream-camera
      container-class="main-cam-container"
      container-id="main-cam-container"
      :stream="mainStream"
      :minimized="boardEnabled"
    />

    <div
      v-if="attachedStreams.length"
      id="attached-streams"
      class="attached-streams"
    >
      <stream-camera
        v-for="stream in attachedStreams"
        :key="stream.id"
        :stream="stream"
        :click-action="() => swapCams(stream)"
        :minimized="boardEnabled"
      />
    </div>

    <template v-if="!boardEnabled">
      <stream-steps-component is-mobile />
    </template>
  </div>
</template>

<script>

import StreamCamera from '../StreamCamera.vue'
import StreamBoards from '../StreamBoards.vue'
import StreamStepsComponent from '../StreamStepsComponent.vue'

export default {
  name: 'MobileStreamGrid',
  components: { StreamStepsComponent, StreamBoards, StreamCamera },
  props: {
    streams: {
      type: Array,
      default: () => [],
    },
    boardEnabled: {
      type: Boolean,
      default: false,
    },
    swapCams: {
      type: Function,
      required: true,
    },
    screenshot: {
      type: String,
      default: '',
    },
  },
  computed: {
    mainStream() {
      return this.streams.find(stream => stream.main)
    },
    attachedStreams() {
      return this.streams.filter(stream => !stream.main)
    },
    backgroundColor() {
      return '#e7e8ec'
    },
  },
}
</script>

<style scoped lang="scss">

</style>
