<template>
  <div>
    <div class="d-flex align-items-end header-buttons">
      <b-button
        v-if="selectedLessonSteps.length"
        :variant="currentSteps ? 'flat-primary' : 'flat-secondary'"
        class="btn-icon mr-50 mb-50 ml-50"
        title="Текущий урок"
        @click="showCurrentSteps(!currentSteps)"
      >
        <feather-icon
          size="18"
          icon="GridIcon"
        />
      </b-button>
      <b-tabs nav-class="m-0">
        <b-tab
          v-for="(tab, tab_index) in tabs"
          :key="tab_index"
          @click="showCurrentSteps(false); courseType = tab.type"
        >
          <template #title>
            <span>{{ tab.title }}</span>
          </template>
        </b-tab>
      </b-tabs>

      <template v-if="!hideCloseButton">
        <div class="flex-fill" />
        <b-button
          v-if="!persistent"
          variant="flat-secondary"
          class="btn-icon my-50 mr-50"
          size="sm"
          @click="hidePanel"
        >
          <feather-icon
            size="22"
            class="text-muted"
            icon="XIcon"
          />
        </b-button>
      </template>
    </div>

    <div class="thin-divider" />
  </div>
</template>

<script>
import { BButton, BTabs, BTab } from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  name: 'HeaderButtons',
  components: {
    BButton, BTabs, BTab,
  },
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    additionalTabs: {
      type: Array,
      default: () => [],
    },
    courseTypeChanged: {
      type: Function,
      required: true,
    },
    hidePanel: {
      type: Function,
      default: () => {},
    },
    currentSteps: {
      type: Boolean,
      default: false,
    },
    showCurrentSteps: {
      type: Function,
      default: () => {},
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    hideCloseButton: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    courseType: null,
  }),
  computed: {
    ...mapGetters({
      selectedLessonSteps: 'videoStream/get_steps',
    }),
  },
  watch: {
    courseType() {
      this.courseTypeChanged(this.courseType)
    },
  },
  mounted() {
    this.courseType = this.tabs[0].type
  },
}
</script>

<style scoped lang="scss">
.header-buttons {
  padding-top: 0.5rem;
}
.sidepanel-courses-absolute {
  .header-buttons {
    padding-top: 0;
  }
}
@media (max-width: 600px) {
  .header-buttons {
    flex-wrap: wrap;
  }
}
</style>
